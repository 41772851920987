import * as ReactRedux from 'react-redux'
import * as React from 'react'

import styled from '@emotion/styled'

import * as Configuration from './configuration'

const ItemsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const ItemContainer = styled.a`
  text-decoration: none;
  outline: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  padding: 6px;
`

const Item = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export function SocialMediaIcons() {
  const socialMediaItems = ReactRedux.useSelector(state =>
    Configuration.getSocialMediaLinks(state.configuration)
  )
  const language = ReactRedux.useSelector(state =>
    Configuration.getCurrentLanguage(state.configuration)
  )

  if (socialMediaItems.length > 0) {
    return (
      <ItemsContainer>
        {socialMediaItems.map(item => (
          <ItemContainer
            href={item.links[language]}
            target="_blank"
            key={`${item.name}_${item.image.url}`}
          >
            <Item src={item.image.url} alt={item.name} />
          </ItemContainer>
        ))}
      </ItemsContainer>
    )
  }

  return null
}
